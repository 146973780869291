import React from "react";
import { db } from "../../firebase";
import {
  collection,
  getDocs,
  query,
  limit,
  setDoc,
  doc,
} from "firebase/firestore";

export default () => {
  const [nftEnabled, setNftEnabled] = React.useState(false);

  async function fetchNftEnable() {
    const results = await getDocs(
      query(collection(db, "nft-config"), limit(1))
    );
    const cvData = results.docs.at(0).data();

    setNftEnabled(cvData.show_nft_button);
  }

  async function toggleNftEnabled() {
    const enabled = !nftEnabled;
    setNftEnabled(!nftEnabled);
    const results = await getDocs(
      query(collection(db, "nft-config"), limit(1))
    );
    const data = {
      ...results.docs.at(0).data(),
      show_nft_button: enabled,
    };
    await setDoc(doc(db, "nft-config", results.docs.at(0).id), data);
  }

  React.useEffect(() => {
    fetchNftEnable();
  }, []);

  return (
    <div>
      <div style={{ paddingBottom: "10px", paddingTop: "10px" }}>
        <input
          type="checkbox"
          id="showCV"
          checked={nftEnabled}
          onChange={toggleNftEnabled}
        />
        <label htmlFor="showCV" style={{ paddingLeft: "5px" }}>
          Afficher bouton NFT
        </label>
      </div>
      <div>@TODO</div>
    </div>
  );
};
