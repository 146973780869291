import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import "./TextEditorModal.css";

export default ({ onSave, onCancel }) => {
  const [leftBlockValue, setLeftBlockValue] = useState("");
  const [rightBlockValue, setRightBlockValue] = useState("");

  return (
    <div className="admin-text-editor-container">
      <div className="admin-text-editor">
        <div className="admin-text-editor-top">
          <div>Texte</div>
          <div onClick={onCancel}>❌</div>
        </div>

        <div style={{ height: "150px", marginTop: "10px" }}>
          <ReactQuill
            theme="snow"
            value={leftBlockValue}
            onChange={setLeftBlockValue}
          />
        </div>

        <div style={{ height: "150px", marginTop: "50px" }}>
          <ReactQuill
            theme="snow"
            value={rightBlockValue}
            onChange={setRightBlockValue}
          />
        </div>

        <div style={{ marginTop: "50px", display: "flex", gap: "10px" }}>
          <button onClick={() => onSave({ leftBlockValue, rightBlockValue })}>
            Enregistrer
          </button>
          <button onClick={onCancel}>Annuler</button>
        </div>
      </div>
    </div>
  );
};
