import React from "react";
import { db } from "../../firebase";
import {
  collection,
  getDocs,
  query,
  limit,
  setDoc,
  doc,
} from "firebase/firestore";
import uploadFile from "../upload-file";

export default function EditCV() {
  const [cvEnabled, setCvEnabled] = React.useState(false);
  const [cvUrl, setCvUrl] = React.useState("");

  async function fetchCV() {
    const results = await getDocs(query(collection(db, "cv"), limit(1)));
    const cvData = results.docs.at(0).data();

    setCvEnabled(cvData.is_cv_enabled);
    setCvUrl(cvData.cv_url);
  }

  React.useEffect(() => {
    fetchCV();
  }, []);

  const [fileIsUploading, setFileIsUploading] = React.useState(false);

  async function handleOnNewMediaInput(e) {
    setFileIsUploading(true);
    const mediaUrl = await uploadFile({ file: e.target.files[0] });
    const results = await getDocs(query(collection(db, "cv"), limit(1)));
    const data = {
      ...results.docs.at(0).data(),
      cv_url: mediaUrl,
    };
    await setDoc(doc(db, "cv", results.docs.at(0).id), data);
    setFileIsUploading(false);
  }

  async function toggleCvEnabled() {
    const enabled = !cvEnabled;
    setCvEnabled(!cvEnabled);
    const results = await getDocs(query(collection(db, "cv"), limit(1)));
    const data = {
      ...results.docs.at(0).data(),
      is_cv_enabled: enabled,
    };
    await setDoc(doc(db, "cv", results.docs.at(0).id), data);
  }

  return (
    <div>
      <div style={{ paddingBottom: "10px", paddingTop: "10px" }}>
        <input
          type="checkbox"
          id="showCV"
          checked={cvEnabled}
          onChange={toggleCvEnabled}
        />
        <label htmlFor="showCV" style={{ paddingLeft: "5px" }}>
          Afficher le CV
        </label>
      </div>
      {fileIsUploading && <div>Chargement...</div>}
      {!fileIsUploading && (
        <input type="file" onChange={(e) => handleOnNewMediaInput(e)} />
      )}
    </div>
  );
}
