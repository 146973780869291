import React, { useState, useEffect, useRef } from "react";
import OrderModal from "./order-modal/OrderModal";
import "./NftEditor.css";

function useEffectOnce(fn) {
  const ref = React.useRef(false);
  React.useEffect(() => {
    if (!ref.current) {
      fn();
    }
    return () => {
      ref.current = true;
    };
  }, []);
}

function renderSvgDocument(svgDocument) {
  return (
    "data:image/svg+xml," +
    encodeURIComponent(svgDocument?.documentElement.outerHTML)
  );
}

export default ({ svgFile, nftId = "" }) => {
  const [colors, setColors] = useState([
    "red",
    "blue",
    "green",
    "yellow",
    "grey",
    "magenta",
    "violet",
    "cyan",
  ]);
  const [pathsColorsMapping, setPathsColorsMapping] = useState([]);
  const [svgDocument, setSvgDocument] = useState(null);
  const [currentEditedColorMapping, setCurrentEditedColorMapping] = useState(0);
  const svgContainerElement = useRef(null);
  const [openOrderModal, setOpenOrderModal] = useState(false);

  // useEffect(() => {
  //   if (svgDocument) {
  //     console.log(svgDocument.documentElement);
  //     svgContainerElement.current.appendChild(svgDocument.documentElement);
  //   }
  // }, []);

  useEffectOnce(async () => {
    const res = await fetch(svgFile);
    const svgText = await res.text();
    const newSvgDocument = new DOMParser().parseFromString(svgText, "text/xml");
    setSvgDocument(newSvgDocument);
    const paths = newSvgDocument.querySelectorAll("path");

    const extractedColors = ["#FFFFFF00"];
    const mapping = [];
    let i = 0;
    for (const path of paths) {
      if (path.getAttribute("fill")) {
        mapping.push({
          path: path,
          type: "fill",
          color: path.getAttribute("fill"),
        });
        extractedColors.push(path.getAttribute("fill"));
      }
      if (path.getAttribute("stroke")) {
        mapping.push({
          path: path,
          type: "stroke",
          color: path.getAttribute("stroke"),
        });
        extractedColors.push(path.getAttribute("stroke"));
      }
      i++;
    }

    setColors([...new Set(extractedColors)]);

    setPathsColorsMapping(mapping);

    svgContainerElement.current.appendChild(newSvgDocument.documentElement);
  });

  function focusCurrentEditedPath(index) {
    // animate path blink
    const mapping = pathsColorsMapping[index];
    console.log(mapping.path);
    mapping.path.classList.add("nft-current-edited-path");
    setTimeout(() => {
      mapping.path.classList.remove("nft-current-edited-path");
      // setCurrentEditedColorMapping(index);
    }, 3000);
    setCurrentEditedColorMapping(index);
  }

  // function updatePathColor({ type = "fill", path, color }) {
  //   console.log("pathsColorsMapping", pathsColorsMapping);
  //   const newMapping = [...pathsColorsMapping];
  //   console.log("newMapping", newMapping);
  //   const item = newMapping.find((i) => i.path === path && i.type === type);
  //   item ? (item.color = color) : null;
  //   console.log("item", item);
  //   // setPathsColorsMapping(newMapping);
  // }

  function selectColorForPath({ color }) {
    const mapping = pathsColorsMapping[currentEditedColorMapping];
    console.log("pathsColorsMapping", pathsColorsMapping);
    if (mapping.type === "fill") {
      mapping.path.setAttribute("fill", color);
    }
    if (mapping.type === "stroke") {
      mapping.path.setAttribute("stroke", color);
    }
    mapping.color = color;
    setPathsColorsMapping([...pathsColorsMapping]);
  }

  return (
    <>
      <div className="nft-page-container">
        <div className="nft-image-container">
          {/* <img src={renderSvgDocument(svgDocument)} /> */}
          <div>
            <div
              className="nft-image-container-svg-container"
              ref={svgContainerElement}
            ></div>
          </div>
        </div>

        <div className="nft-color-picker">
          <div class="nft-color-picker-top">
            <div className="nft-color-picker-title">Colors</div>
            <div
              onClick={() => setOpenOrderModal(true)}
              class="order-now-button"
            >
              ORDER NOW
            </div>
          </div>

          <div className="nft-color-list">
            {pathsColorsMapping.map((mapping, index) => (
              <div
                key={index}
                className={currentEditedColorMapping === index ? "opened" : ""}
                onClick={() => focusCurrentEditedPath(index)}
              >
                {index}
              </div>
            ))}
          </div>
          <div className="nft-choose-color">
            {colors.map((color, i) => (
              <div
                key={color}
                style={{
                  background: color === "#FFFFFF00" ? "#FFFFFF" : color,
                }}
                className={
                  pathsColorsMapping[currentEditedColorMapping]?.color === color
                    ? "selected"
                    : ""
                }
                onClick={() => selectColorForPath({ color })}
              ></div>
            ))}
          </div>
        </div>
      </div>
      {openOrderModal && (
        <OrderModal
          availableColors={colors}
          nftId={nftId}
          customNftId={
            nftId +
            "-" +
            pathsColorsMapping
              .map((i) => i.color)
              .join("_")
              .replace(/#/g, "")
              .toLocaleLowerCase()
          }
          svgText={svgContainerElement.current?.innerHTML}
          onClose={() => setOpenOrderModal(false)}
        />
      )}
    </>
  );
};
