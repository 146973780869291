export default function pSwiper(container) {
  const containerElement = container || document.body;
  function computeRealHeight() {
    console.log("p-swiper : computeRealHeight");
    document.documentElement.style.setProperty(
      "--p-swiper-full-page-height",
      window.innerHeight + "px"
    );
  }

  if (containerElement.clientHeight !== window.innerHeight) {
    computeRealHeight();
  }

  window.addEventListener("resize", () => {
    if (containerElement.clientHeight != window.innerHeight) {
      computeRealHeight();
    }
  });

  function handleDirection({ el }) {
    el.addEventListener("click", (e) => {
      const direction = el.getAttribute("p-swiper-navigate-direction");
      // const layoutElement = pageElement.parentElement;

      if (["up", "down"].includes(direction)) {
        const layoutElement = el.closest(".p-swiper-page-vertical-layout");
        let pageElement = el.closest(".p-swiper-page");
        if (
          pageElement.parentElement.classList.contains(
            "p-swiper-page-horizontal-layout"
          )
        ) {
          pageElement = pageElement.parentElement;
        }
        const index = [...layoutElement.children].indexOf(pageElement);
        layoutElement.scrollTo({
          top: (index + (direction === "down" ? 1 : -1)) * window.innerHeight,
          behavior: "smooth",
        });
      }
      if (["left", "right"].includes(direction)) {
        const layoutElement = el.closest(".p-swiper-page-horizontal-layout");
        const pageElement = el.closest(".p-swiper-page");
        const index = [...layoutElement.children].indexOf(pageElement);
        layoutElement.scrollTo({
          left: (index + (direction === "right" ? 1 : -1)) * window.innerWidth,
          behavior: "smooth",
        });
      }
    });
  }
  document
    .querySelectorAll("[p-swiper-navigate]")
    .forEach((el) => handleDirection({ el }));
}
