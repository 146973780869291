import React, { useState } from "react";
import "./OrderModal.css";
import CloseIcon from "./icons/CloseIcon";
import Loader from "./loader/Loader";
import {
  doc,
  setDoc,
  getDoc,
  getDocs,
  collection,
  query,
  where,
  getCountFromServer,
  writeBatch,
} from "firebase/firestore";
import { db } from "../../../firebase";

function shuffleArray(arr) {
  const array = [...arr];
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

function renderSvgDocument(svgText) {
  return "data:image/svg+xml," + encodeURIComponent(svgText);
}

export default function ({
  availableColors,
  nftId,
  customNftId,
  svgText,
  onClose,
}) {
  //   console.log({ svgText });
  const [isLoading, setIsLoading] = useState(false);
  const [orderNumber, setOrderNumber] = useState("");
  const [openSeaUsername, setOpenSeaUsername] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [customOrderCount, setCustomOrderCount] = useState(0);

  async function handleSubmitOrder({ customCount }) {
    setIsLoading(true);
    // const randomId = Math.round(Math.random() * 9999999999);
    const randomId = (Math.random() * 36).toString(36).slice(-9);

    const q = query(
      collection(db, "orders"),
      where("unique_id", "==", customNftId)
    );
    const existingCount = (await getCountFromServer(q)).data().count;

    if (!customCount && existingCount > 0) {
      setIsLoading(false);
      alert("This NFT is already taken");
      return;
    }

    await setDoc(doc(db, "orders", randomId), {
      unique_id: customNftId,
      custom_count: customCount || null,
      opensea_username: openSeaUsername,
      svg_document: svgText,
      date_timestamp: new Date().getTime(),
    });
    setOrderNumber(randomId);
    setShowSuccessMessage(true);
  }

  async function orderPack({ count }) {
    setIsLoading(true);
    let randomId;

    let batch = writeBatch(db);
    for (let i = 0; i < count; i++) {
      const uniqueId =
        nftId +
        "-" +
        shuffleArray(availableColors)
          .join("_")
          .replace(/#/g, "")
          .toLocaleLowerCase();

      randomId = (Math.random() * 36).toString(36).slice(-9);
      batch.set(doc(db, "orders", randomId), {
        unique_id: uniqueId,
        opensea_username: openSeaUsername,
        svg_document: svgText,
        date_timestamp: new Date().getTime(),
      });
      if (i % 15 === 0) {
        await batch.commit();
        batch = writeBatch(db);
      }
    }

    await batch.commit();
    setIsLoading(false);
    setOrderNumber(randomId);
    setShowSuccessMessage(true);
  }

  return (
    <div class="nft-order-modal">
      <div class="nft-order-modal-wrapper">
        <CloseIcon
          style={{
            height: "48px",
            position: "fixed",
            top: "20px",
            right: "20px",
          }}
          onClick={onClose}
        />
        <div class="nft-order-modal-top">
          <div class="nft-order-modal-title">Order this NFT</div>
        </div>

        {showSuccessMessage ? (
          <>
            <div style={{ height: "40px" }}></div>
            <div style={{ fontSize: "30px", fontFamily: "monospace" }}>
              This NFT has been ordered, your order number is{" "}
              <b>#{orderNumber}</b>, thanks !
            </div>

            <div style={{ height: "40px" }}></div>
            <div
              class="order-button"
              style={{ background: "black", color: "#fff" }}
              onClick={() => location.reload()}
            >
              Ok
            </div>
          </>
        ) : (
          <div class="nft-order-modal-fields">
            <div class="nft-order-modal-fields-item">
              <div class="title">Opensea username</div>
              <input
                placeholder="Enter your Opensea username..."
                onChange={(e) => setOpenSeaUsername(e.target.value)}
              />
            </div>
            <div class="nft-order-modal-fields-item">
              <div class="title">ART ID</div>
              {/* <input placeholder="6521536-312321_321312_312" disabled /> */}
              <div
                style={{
                  fontFamily: "monospace",
                  overflowWrap: "break-word",
                  maxWidth: "80vw",
                }}
              >
                {customNftId}
              </div>
            </div>
            <div class="nft-order-modal-fields-item">
              <div class="title">Preview</div>
              <img
                src={renderSvgDocument(svgText)}
                style={{ height: "100px" }}
              />
            </div>
            <div class="nft-order-modal-fields-item">
              {isLoading && (
                <>
                  <div class="order-button">
                    <Loader />
                  </div>
                  <div style={{ height: "10px" }}></div>
                  <div>Loading please wait...</div>
                </>
              )}
              {!isLoading && (
                <>
                  <div class="order-button" onClick={handleSubmitOrder}>
                    Order this NFT
                  </div>
                  <div style={{ height: "10px" }}></div>
                  <div
                    class="order-button"
                    style={{ color: "#ff0090" }}
                    onClick={() => orderPack({ count: 10 })}
                  >
                    Order 10x (random)
                  </div>
                  <div style={{ height: "10px" }}></div>
                  <div
                    class="order-button"
                    style={{ color: "#fff700" }}
                    onClick={() => orderPack({ count: 100 })}
                  >
                    Order 100x (random)
                  </div>
                  {/* <div style={{ height: "10px" }}></div>
                  <div
                    class="order-button"
                    style={{ color: "#158209" }}
                    onClick={() => handleSubmitOrder({ customCount: 1000 })}
                  >
                    Order 1000x (random)
                  </div> */}
                  <div style={{ height: "10px" }}></div>
                  <div
                    class="order-button"
                    style={{
                      color: "#158209",
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                      justifyContent: "center",
                    }}
                  >
                    Order custom
                    <input
                      type="number"
                      style={{ background: "transparent", width: "100px" }}
                      onChange={(e) => setCustomOrderCount(e.target.value)}
                    ></input>
                    <div
                      onClick={() =>
                        handleSubmitOrder({
                          customCount: Math.min(customOrderCount, 134217728),
                        })
                      }
                      style={{
                        background: "black",
                        color: "#fff",
                        padding: "8px 10px",
                        borderRadius: "4px",
                      }}
                    >
                      OK
                    </div>
                  </div>
                  {/* <div style={{ height: "10px" }}></div>
                  <div
                    class="order-button"
                    style={{ color: "#0062ff" }}
                    onClick={() => orderPack({ count: 10000 })}
                  >
                    Order 10000x (random)
                  </div> */}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
