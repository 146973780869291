import React from "react";

// function sharpen(ctx, w, h, mix) {
//   var weights = [0, -1, 0, -1, 5, -1, 0, -1, 0],
//     katet = Math.round(Math.sqrt(weights.length)),
//     half = (katet * 0.5) | 0,
//     dstData = ctx.createImageData(w, h),
//     dstBuff = dstData.data,
//     srcBuff = ctx.getImageData(0, 0, w, h).data,
//     y = h;

//   while (y--) {
//     x = w;

//     while (x--) {
//       var sy = y,
//         sx = x,
//         dstOff = (y * w + x) * 4,
//         r = 0,
//         g = 0,
//         b = 0,
//         a = 0;

//       for (var cy = 0; cy < katet; cy++) {
//         for (var cx = 0; cx < katet; cx++) {
//           var scy = sy + cy - half;
//           var scx = sx + cx - half;

//           if (scy >= 0 && scy < h && scx >= 0 && scx < w) {
//             var srcOff = (scy * w + scx) * 4;
//             var wt = weights[cy * katet + cx];

//             r += srcBuff[srcOff] * wt;
//             g += srcBuff[srcOff + 1] * wt;
//             b += srcBuff[srcOff + 2] * wt;
//             a += srcBuff[srcOff + 3] * wt;
//           }
//         }
//       }

//       dstBuff[dstOff] = r * mix + srcBuff[dstOff] * (1 - mix);
//       dstBuff[dstOff + 1] = g * mix + srcBuff[dstOff + 1] * (1 - mix);
//       dstBuff[dstOff + 2] = b * mix + srcBuff[dstOff + 2] * (1 - mix);
//       dstBuff[dstOff + 3] = srcBuff[dstOff + 3];
//     }
//   }

//   ctx.putImageData(dstData, 0, 0);
// }

export default function ImgToCanvas({ src }) {
  const canvasElement = React.useRef(null);
  const imgElement = React.useRef(null);

  React.useEffect(() => {
    // Get the canvas and context
    const ctx = canvasElement.current.getContext("2d");

    // Once the image is loaded, draw it onto the canvas
    imgElement.current.onload = function () {
      // Optionally, you can resize the canvas to the image's dimensions

      // let height, width;

      // console.log(src);
      // if (imgElement.current.height > imgElement.current.width) {
      //   console.log(1);
      //   height = window.innerHeight - 500;
      //   width = (imgElement.current.width / imgElement.current.height) * height;
      // } else {
      //   console.log(2);
      //   width = window.innerWidth - 200;
      //   height = (imgElement.current.height * width) / imgElement.current.width;
      // }

      const height = imgElement.current.height / 2;
      const width = imgElement.current.width / 2;

      canvasElement.current.width = width;
      canvasElement.current.height = height;

      // Draw the image on the canvas
      ctx.drawImage(imgElement.current, 0, 0, width, height);
      // sharpen(ctx, width, height, 0.3);

      canvasElement.current.style.maxWidth = "70vw";
      canvasElement.current.style.maxHeight = "70vh";
      // canvasElement.current.style.width = "100px";
      // canvasElement.current.style.height = "100px";

      imgElement.current.remove();
    };
  }, []);

  return (
    <div>
      <canvas ref={canvasElement}></canvas>

      <img src={src} style={{ display: "none" }} ref={imgElement} />
    </div>
  );
}
