import React from "react";
import "./NftPage.css";
import pSwiper from "../_libs/p-swiper/p-swiper.js";

import "swiper/css/bundle";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";

import NftEditor from "./nft-editor/NftEditor";
export default ({ onCloseNftPage }) => {
  React.useEffect(() => {
    pSwiper(document.querySelector(".nft-page"));
  });
  return (
    <div
      className="nft-page p-swiper-page p-swiper-page-horizontal-layout"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        background: "#98E371",
      }}
    >
      <div className="app-page p-swiper-page page-layout page-home">
        <div className="page-title">NFT</div>
        <div
          className=""
          onClick={onCloseNftPage}
          style={{
            cursor: "pointer",
            paddingTop: "10px",
            fontSize: "20px",
          }}
        >
          Retour
        </div>
        <div class="nft-page-text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </div>
        <div
          className="page-arrow page-arrow--right"
          p-swiper-navigate="true"
          p-swiper-navigate-direction="right"
        ></div>
      </div>

      <div className="app-page p-swiper-page">
        <div
          className="page-arrow page-arrow--left"
          p-swiper-navigate="true"
          p-swiper-navigate-direction="left"
        ></div>
        <NftEditor svgFile="/nft-files/nft-1.svg" nftId="1111" />
        <div
          className="page-arrow page-arrow--right"
          p-swiper-navigate="true"
          p-swiper-navigate-direction="right"
        ></div>
      </div>
      <div className="app-page p-swiper-page">
        <div
          className="page-arrow page-arrow--left"
          p-swiper-navigate="true"
          p-swiper-navigate-direction="left"
        ></div>
        <NftEditor svgFile="/nft-files/nft-2.svg" nftId="2222" />
        <div
          className="page-arrow page-arrow--right"
          p-swiper-navigate="true"
          p-swiper-navigate-direction="right"
        ></div>
      </div>
      <div className="app-page p-swiper-page">
        <div
          className="page-arrow page-arrow--left"
          p-swiper-navigate="true"
          p-swiper-navigate-direction="left"
        ></div>
        <NftEditor svgFile="/nft-files/nft-3.svg" nftId="3333" />
      </div>
    </div>
  );
};
