import "./NftOrders.css";
import React, { useEffect, useState } from "react";

import { collection, getDocs, query, orderBy, limit } from "firebase/firestore";

import { db } from "../../firebase";

function renderSvgDocument(svgText) {
  return "data:image/svg+xml," + encodeURIComponent(svgText);
}

export default () => {
  const [orders, setOrders] = useState([]);

  async function fetchOrders() {
    const results = await getDocs(query(collection(db, "orders"), limit(99)));
    setOrders(
      results.docs.map((doc) => {
        return {
          // id: doc.id,
          ...doc.data(),
        };
      })
    );
  }

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <div className="nft-orders">
      <table>
        <thead>
          <tr>
            <th>Opensea username</th>
            <th>Demande custom</th>
            <th>ID</th>
            <th>Preview</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr>
              <td>{order.opensea_username}</td>
              <td>{order.custom_count ? `x${order.custom_count}` : "-"}</td>
              <td>{order.unique_id}</td>
              <td>
                <img
                  src={renderSvgDocument(order.svg_document)}
                  style={{ height: "100px" }}
                />
              </td>
              <td>{new Date(order.date_timestamp).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
