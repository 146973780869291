import * as React from "react";
import uploadFile from "./upload-file";
import savePage from "./save-page";
import TextEditorModal from "./text-editor/TextEditorModal";

import { db } from "../firebase";
import { deleteDoc, doc } from "firebase/firestore";

export default function PageEditorForm({ page, onDeletePage }) {
  const [editedPage, setEditedPage] = React.useState(page);
  const [fileIsUploading, setFileIsUploading] = React.useState(false);
  const [newMediaTitle, setNewMediaTitle] = React.useState("");
  const [textEditorOpened, setTextEditorOpened] = React.useState(false);
  const inputMediaRef = React.createRef();

  function handleFieldChange({ field, newValue }) {
    setEditedPage({
      ...editedPage,
      [field]: newValue,
    });
  }
  function handleDeleteMedia({ media }) {
    const newEditedPage = {
      ...editedPage,
      medias: editedPage.medias.filter((i) => i !== media),
    };
    setEditedPage(newEditedPage);
    savePage({ page: newEditedPage });
  }
  function handleClickUploadNewMedia() {
    inputMediaRef.current.click();
  }
  async function handleOnNewMediaInput(e) {
    setFileIsUploading(true);
    const mediaUrl = await uploadFile({ file: e.target.files[0] });
    setFileIsUploading(false);
    const newMedia = {
      media_order: editedPage.medias.length,
      media_type: "image",
      media_url: mediaUrl,
      media_title: newMediaTitle,
    };
    setNewMediaTitle("");
    setEditedPage({
      ...editedPage,
      medias: editedPage.medias.concat([newMedia]),
    });
  }

  async function handleSave() {
    savePage({ page: editedPage });
    alert("Page saved");
  }

  async function deletePage() {
    await deleteDoc(doc(db, "pages", editedPage.id));
    onDeletePage({ page: editedPage });
    alert("Page has been deleted");
  }

  function onTextEditorSave({ leftBlockValue, rightBlockValue }) {
    const newMedia = {
      text: {
        left_text: leftBlockValue,
        right_text: rightBlockValue,
      },
    };
    const newEditedPage = {
      ...editedPage,
      medias: editedPage.medias.concat([newMedia]),
    };
    setEditedPage(newEditedPage);
    savePage({ page: newEditedPage });
    setTextEditorOpened(false);
    alert("Page saved");
  }

  return (
    <>
      <div className="admin-page-list-page">
        <div className="admin-page-list-page-fields">
          <label>Titre de la page :</label>
          <input
            type="text"
            value={editedPage.title}
            onChange={(e) =>
              handleFieldChange({
                field: "title",
                newValue: e.target.value,
              })
            }
          />

          <label>Couleur du fond</label>
          <input
            type="text"
            value={editedPage.background}
            onChange={(e) =>
              handleFieldChange({
                field: "background",
                newValue: e.target.value,
              })
            }
          />

          <label>Ordre de la page</label>
          <input
            type="number"
            value={editedPage.order}
            onChange={(e) =>
              handleFieldChange({
                field: "order",
                newValue: e.target.value,
              })
            }
          />
        </div>

        <div style={{ height: "20px" }}></div>
        <h3>Médias ({editedPage.medias.length})</h3>
        <div className="admin-page-list-page-medias">
          {editedPage.medias.map((media, index) => (
            <div className="admin-page-list-page-medias-item" key={index}>
              {media.text && (
                <>
                  <div
                    className="admin-page-list-page-medias-item-text-preview"
                    dangerouslySetInnerHTML={{
                      __html: media.text.left_text,
                    }}
                  ></div>
                  <div
                    className="admin-page-list-page-medias-item-text-preview"
                    dangerouslySetInnerHTML={{
                      __html: media.text.right_text,
                    }}
                  ></div>
                </>
              )}
              {!media.text && (
                <>
                  <img src={media.media_url} />
                  {media.media_title}
                </>
              )}
              <button onClick={() => handleDeleteMedia({ media })}>
                ❌ Supprimer
              </button>
            </div>
          ))}
          <div className="admin-page-list-page-medias-item-new">
            {fileIsUploading && <div>Loading...</div>}
            {!fileIsUploading && (
              <div>
                <div style={{ background: "#00000011", padding: "5px" }}>
                  <button onClick={() => handleClickUploadNewMedia()}>
                    Nouvelle image
                  </button>
                  <input
                    type="file"
                    ref={inputMediaRef}
                    onChange={(e) => handleOnNewMediaInput(e)}
                  />
                  <input
                    type="text"
                    placeholder="Titre de l'image..."
                    value={newMediaTitle}
                    onChange={(e) => setNewMediaTitle(e.target.value)}
                  />
                </div>

                <button
                  onClick={() => setTextEditorOpened(true)}
                  style={{ marginTop: "20px" }}
                >
                  Nouveau texte
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="admin-page-list-page-bottom-actions">
          <button onClick={handleSave}>✅ Enregistrer</button>
          <button onClick={deletePage}>❌ Supprimer la page</button>
        </div>
        {textEditorOpened && (
          <TextEditorModal
            onSave={onTextEditorSave}
            onCancel={() => setTextEditorOpened(false)}
          />
        )}
      </div>
    </>
  );
}
