import * as React from "react";
import "./AdminPage.css";
import PageEditorForm from "./PageEditorForm.jsx";
import { db } from "../firebase";
import NftOrders from "./nft-orders/NftOrders";
import EditNftList from "./edit-nft-list/EditNftList";

import { collection, getDocs, query, orderBy, limit } from "firebase/firestore";
import EditCV from "./edit-cv/EditCV.jsx";

function useEffectOnce(fn) {
  const ref = React.useRef(false);
  React.useEffect(() => {
    if (!ref.current) {
      fn();
    }
    return () => {
      ref.current = true;
    };
  }, []);
}

function blankNewPage() {
  const randomId = (Math.random() * 36).toString(36).slice(-9);
  return {
    id: randomId,
    title: "",
    background: "#ff0000",
    medias: [],
  };
}

export default () => {
  const [pages, setPages] = React.useState([]);

  useEffectOnce(async () => {
    const results = await getDocs(
      query(collection(db, "pages"), orderBy("order", "asc"), limit(99))
    );
    setPages(
      results.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      })
    );
  });

  function addNewPage() {
    setPages([...pages, blankNewPage()]);
  }

  function onDeletePage({ page }) {
    setPages(pages.filter((i) => i.id !== page.id));
  }

  return (
    <div style={{ padding: "20px" }}>
      <h1>Pages</h1>
      <div className="admin-page-list">
        {pages.map((page) => (
          <PageEditorForm
            page={page}
            key={page.id}
            onDeletePage={onDeletePage}
          />
        ))}
        <div style={{ height: "40px" }}></div>
        <button style={{ fontSize: "24px" }} onClick={addNewPage}>
          ➕ Add new page
        </button>
      </div>

      <div style={{ height: "30px" }}></div>

      <h1>Demandes NFT</h1>
      <NftOrders />

      <div style={{ height: "30px" }}></div>

      <h1>Liste NFT</h1>
      <EditNftList />

      <div style={{ height: "30px" }}></div>
      <h1>Edition du CV</h1>
      <EditCV />
    </div>
  );
};
