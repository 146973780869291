import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAqDXy8Q6iC5Uit4Uys578qaneflVjt1CY",
  authDomain: "william-victorino.firebaseapp.com",
  projectId: "william-victorino",
  storageBucket: "william-victorino.appspot.com",
  messagingSenderId: "527912841168",
  appId: "1:527912841168:web:2f22c1f71b873596a093d5",
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
